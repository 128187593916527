import React from "react";
import { useDispatch, useSelector } from "react-redux";
// components mui
import { Modal, Box, Typography, Button } from "@mui/material";
// Actions
import { setModal, setModalData } from "../../../redux/actions/modals";

// components
import ModalHeader from "../ModalHeader";
import { setDisclaimer } from "../../../redux/actions/settings";

const ModalDisclaimer = () => {
  // hooks
  const dispatch = useDispatch();
  // selectors
  const currentModal = useSelector((state) => state.modals.modal);
  const disclaimer = useSelector((state) => state.settings.disclaimer);

  const closeModal = () => {
    dispatch(setDisclaimer(true))
    dispatch(setModalData(null));
    dispatch(setModal(null));
  };

  return (
    <Modal open={currentModal === "Disclaimer" && disclaimer != true} sx={{ justifyContent: "center", display: "flex", alignItems: "center" }}>
      <Box sx={{ minWidth: "sm", maxHeight: "900px", width: "100%", maxWidth: "500px", marginY: "20px", bgcolor: "background.paper", borderRadius: "10px", overflowY: "auto" }}>
        <ModalHeader title={"Disclaimer"} hideCloseButton={true} />
        <Box paddingX={"15px"} paddingTop="15px">

          <Typography component={"p"} variant={"body2"}>
Before participating in the IDO of KoinDX DAO, you must confirm that you are not located, incorporated, or a citizen or resident of the United States of America, People&apos;s Republic of China, Bermuda, Burundi, Central African Republic, Cuba, Democratic Republic of Congo, Eritrea, Guinea-Bissau, Iran, Libya, Mali, North Korea, Palestine, Republic of Seychelles, Somalia, South Sudan, Sudan, Syria, Western Sahara, Yemen, Crimea and Sevastopol, or any other state, country, or jurisdiction where participation in this launch would be illegal according to applicable law. </Typography>
        </Box>
        <Box padding={"15px"} display={"flex"} justifyContent={"space-around"} alignContent={"center"}>
          <Button href="https://koindx.com" variant="outlined" size={"large"} color="warning" sx={{minWidth: "40%"}}>Leave</Button>
          <Button onClick={closeModal} variant="contained" size={"large"} color="warning" sx={{minWidth: "40%"}}>Confirm</Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ModalDisclaimer;
