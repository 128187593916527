import { Contract, Provider, Signer, utils } from "koilib";
import SaleAbi from "../assets/contracts/sale-abi.json"
import KoinDXTokenAbi from "../assets/contracts/koindx-abi.json"

// variables
const provider = new Provider([ process.env.REACT_APP_RPC ]);
const signer = Signer.fromSeed("demo seed");

export let KoinosTokenContract = (address, _provider, _signer) => new Contract({
  id: address,
  abi: utils.tokenAbi,
  provider: _provider ? _provider : provider,
  signer: _signer ? _signer : signer,
})

export let KoinDXSaleContract = (address, _provider, _signer) => new Contract({
  id: address,
  abi: SaleAbi,
  provider: _provider ? _provider : provider,
  signer: _signer ? _signer : signer
})

export let KoinDXTokenContract = (address, _provider, _signer) => new Contract({
  id: address,
  abi: KoinDXTokenAbi,
  provider: _provider ? _provider : provider,
  signer: _signer ? _signer : signer
})