/* eslint-disable */
import { Divider, Link, Box, Button, Card, CardContent, CardHeader, FormControl, InputBase, Typography, useTheme } from '@mui/material';
import { BigNumber } from "bignumber.js";
import { utils as koilibUtils, Provider } from "koilib";
import { get as _get } from "lodash";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CHAINS_IDS, CHAIN_IDS_TO_NAMES } from '../constants/chains';
import { CONFIG_BASE } from "../constants/configs"

// helpers
import { KoinDXSaleContract, KoinDXTokenContract, KoinosTokenContract } from "../helpers/contracts";

// Actions
import { setModal } from "../redux/actions/modals";

// utils
import { numberPattern } from "../utils/regex";
import CustomKoinConnectButton from '../components/CustomKoinConnectButton';

const IDO = () => {
  // Dispatch to call actions
  const dispatch = useDispatch();
  const Snackbar = useSnackbar();
  const theme = useTheme();
  const navigate = useNavigate();
  // constants
  const idoAmount = 31500000
  const intitialPrice = 0.0115
  const maxKoin = new BigNumber(idoAmount).times(intitialPrice)
  // states
  const [balance, setBalance] = useState(0);
  const [loadingBalance, setLoadingBalance] = useState(false);
  const [inputValue, setInputValue] = useState("0");
  const [currentBlock, setCurrentBlock] = useState(0)
  const [bought, setBought] = useState(false)
  const [buyTokensLoading, setBuyTokensLoading] = useState(false)
  const [allocatedKoin, setAllocatedKoin] = useState(0)
  const [entireAllocatedKoin, setEntireAllocatedKoin] = useState(0)
  const [tokenPrice, setTokenPrice] = useState(intitialPrice)


  const walletSelector = useSelector(state => state.wallet);
  const settingsSelector = useSelector(state => state.settings)
  const disclaimer = useSelector(state => state.settings.disclaimer)

  const network = _get(settingsSelector, "network", null)
  const provider = new Provider(process.env.REACT_APP_RPC)
  const signer = _get(walletSelector, "signer", null)
  const address = _get(walletSelector, "wallet[0].address", "");


  // efects
  useEffect(() => {
    dispatch(setModal("Disclaimer"))
  }, []);

  provider.getHeadInfo().then((data) => {
    setCurrentBlock(data?.head_topology?.height)
  })

  useEffect(() => {
    const loadBlance = async () => {
      try {
        let _balance = 0;
        let _token = null;
        let _sale = null;
        setLoadingBalance(true);
        setInputValue("0");
        _token = await KoinosTokenContract(CONFIG_BASE.tokens.sale.address, _get(walletSelector, "provider", null), _get(walletSelector, "signer", null));
        let addressOwner = _get(walletSelector, "wallet[0].address", null);          
        if (_token) {
          _token.functions.balanceOf({ owner: addressOwner }).then((data) => {
            _balance = koilibUtils.formatUnits(_get(data, 'result.value', 0), _get(network, "decimals", 8))
            setBalance(_balance);
          })
        }
        _sale = await KoinDXSaleContract(CONFIG_BASE.contracts.sale.address, _get(walletSelector, "provider", null), _get(walletSelector, "signer", null));
        if (_sale) {
          await _sale.functions.get_tokens({ from: addressOwner }).then((data) => {
            console.log(data)
            let amount = _get(data, 'result.result.amount', 0)
            if (amount > 0) {
              setBought(true)
            }
            setAllocatedKoin(koilibUtils.formatUnits(amount, _get(network, "decimals", 8)))
          })
        }
      } catch (error) {
        console.log(error)
      }
      setLoadingBalance(false);
    }
    if (_get(walletSelector, "wallet", null) !== null) {
      loadBlance();
    }
  }, [_get(walletSelector, "wallet", null), buyTokensLoading]);

  const actionClose = (snackbarId) => (
    <>
      <Button style={{ color: "white" }} onClick={() => { Snackbar.closeSnackbar(snackbarId) }}>
        Close
      </Button>
    </>
  );

  const setBalancePercent = (val) => {
    let num = new BigNumber(balance)
    setInputValue(num.dividedBy(100).times(val))
  }

  const buyTokens = async () => {
    setBuyTokensLoading(true)

    // prepare contract
    const _ido = await KoinDXSaleContract(CONFIG_BASE.contracts.sale.address, _get(walletSelector, "provider", null), _get(walletSelector, "signer", null));
    _ido.options.onlyOperation = true;

    // prepare contract
    let _token = await KoinDXTokenContract(CONFIG_BASE.tokens.sale.address, provider, signer);
    _token.options.onlyOperation = true;

    if (_ido) {
      let configs = {
        payer: signer.getAddress(),
        sendTransaction: true,
        signTransaction: true
      };

      let operations = [];
      // allowance
      try {
        const approvalParams = {
          owner: signer.getAddress(),
          spender: CONFIG_BASE.contracts.sale.address,
          value: koilibUtils.parseUnits(inputValue + "0", 8)
        }
        let { operation: approveSales } = await _token.functions.approve(approvalParams, configs)
        operations.push(approveSales);
      } catch (error) {
        console.log(error)
        Snackbar.enqueueSnackbar("Error sending transaction", { variant: "error" });
        setBuyTokensLoading(false);
        return;
      }

      console.log(typeof inputValue)

      //  buy tokens
      try {
        const buyTokensParams = {
          from: signer.getAddress(),
          to: signer.getAddress(),
          amount: koilibUtils.parseUnits(inputValue, 8)
        }
        let { operation: buyTokensOperation } = await _ido.functions.buy_tokens(buyTokensParams, configs);
        operations.push(buyTokensOperation);
      } catch (error) {
        console.log(error)
        Snackbar.enqueueSnackbar("Error sending transaction", { variant: "error" });
        setBuyTokensLoading(false);
        return;
      }

      let transaction = null;
      let receipt = null;
      try {
        const tx = await signer.prepareTransaction({ operations: operations, header: configs });
        const { transaction: _transaction, receipt: _receipt } = await signer.sendTransaction(tx);
        transaction = _transaction
        receipt = _receipt
        Snackbar.enqueueSnackbar("Transaction sent", { variant: "info" });
      } catch (error) {
        Snackbar.enqueueSnackbar("Error sending transaction", { variant: "error" });
        console.log(error)
        setLoading(false);
        return;
      }

      const _tx = await transaction.wait("byTransactionId", 120000);
      // Snackbar TX success
      Snackbar.enqueueSnackbar(<Link underline="none" style={{ cursor: "pointer" }} target="_blank" href={CONFIG_BASE.explorer.url  + _tx.blockId}><Typography sx={{ color: "white" }} variant="h6">Transaction success</Typography><Typography sx={{ color: "white" }} variant="subtitle1" component="p">View Block</Typography></Link>, {
        variant: 'success',
        persist: true,
        action: actionClose,
      })
      setBought(true)
    }

    setBuyTokensLoading(false)
  }

  return (
    <Box>
      <Card variant="outlined" sx={{ maxWidth: "500px", marginX: "auto", marginBottom: "20px", borderRadius: "10px", padding: "15px 20px" }}>
        <CardHeader title="Private Sale" sx={{ paddingBottom: "4px" }} />
        <CardContent>
          <Box>
            <Typography component={"h3"} sx={{ color: theme.palette.text.grey2 }}>Welcome to private sale of koindx</Typography>
          </Box>
          <Box sx={{ marginTop: "1em" }}>
            <CustomKoinConnectButton />
          </Box>
          {walletSelector.connected && disclaimer ? (
            <Box sx={{ marginTop: "1em" }}>
              <Box sx={{ border: `1px solid ${theme.palette.background.light}`, borderRadius: "10px", padding: "10px 20px", display: "flex", alignContent: "center", flexDirection: "column" }} marginY={".5em"} display={"flex"} justifyContent={"space-between"}>
                <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                  <Typography variant={"body1"} component={"span"} sx={{ color: "text.grey2" }}>USDT Balance in wallet:</Typography>
                  <Typography variant={"body1"} component={"span"} sx={{ color: "text.grey2" }}>{loadingBalance ? "loading.." : balance}</Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                  <Typography variant={"body1"} component={"span"} sx={{ color: "text.grey2" }}>USDT Allocation in private sale:</Typography>
                  <Typography variant={"body1"} component={"span"} sx={{ color: "text.grey2" }}>{allocatedKoin}</Typography>
                </Box>
              </Box>
              <FormControl sx={{ width: '100%' }}>
                <InputBase placeholder={"0"} type={"text"} value={inputValue} sx={{ fontSize: "22px", color: "text.main", padding: "10px", borderRadius: "10px", backgroundColor: "background.default" }} onChange={(e) => numberPattern(e.currentTarget.value, setInputValue)} />
              </FormControl>
              <Box display={"flex"} justifyContent={"space-around"} alignItems={"center"} paddingY={"10px"}>
                <Button sx={{ width: "48%" }} variant={"outlined"} onClick={() => { setInputValue(balance) }}>Max</Button>
                <Button sx={{ width: "48%" }} variant={"outlined"} onClick={() => { setBalancePercent(50) }}>Half</Button>
              </Box>
              <Button disabled={(inputValue == 0 || inputValue < 5000) || buyTokensLoading == true} variant={"contained"} onClick={buyTokens} sx={{ width: "100%", marginTop: "0.5em" }}>
                {buyTokensLoading ? "loading..." : "BUY"}
              </Button>
            </Box>
          )
            :
            null
          }
        </CardContent>
      </Card>
    </Box >
  )
}

export default IDO;