/* eslint-disable */
import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom"
import { get as _get } from "lodash"
import "./App.css";

// pages
import Layout from "./components/Layout";
import IDO from "./pages/index.js";
import NotFound from "./pages/NotFound"

function AppRouter() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path='*' element={<NotFound />} />
          <Route path="/" element={<IDO />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default AppRouter;