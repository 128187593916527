import { SET_DARKMODE, SET_NETWORK, SET_DISCLAIMER } from "../constants"

// Actions
export const setDarkMode = (darkMode) => ({
  type: SET_DARKMODE,
  payload: darkMode
})
export const setNetwork = (chain_id) => ({
  type: SET_NETWORK,
  payload: chain_id
})
export const setDisclaimer = (disclaimer) => ({
  type: SET_DISCLAIMER,
  payload: disclaimer
})