/* eslint-disable */
import { get as _get } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { setModal } from "../../redux/actions/modals";
import { Button } from '@mui/material'
import Logo from "../../assets/images/koindx_logo.png"
// utils
import { shortedAddress } from "./../../utils/display";

const CustomKoinConnectButton = () => {
  // Dispatch to call actions
  const dispatch = useDispatch();

  // selectors
  const walletSelector = useSelector(state => state.wallet);

  if (!_get(walletSelector, "wallet", null)) {
    return (
      <Button variant="contained" sx={{ boxShadow: "0", width: "100%", borderRadius: "10px" }} onClick={() => dispatch(setModal("Connect"))}>
        Connect
      </Button>
    )
  }

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px", gap: 12 }}>
        <Button
          style={{ display: "flex", alignItems: "center" }}
          type="button"
        >
          {walletSelector !== null ?
            <img
              alt={" Koinos icon"}
              src={ Logo }
              style={{ width: 24, height: 24 }}
            />
            : null}
          Koinos
        </Button>

        <Button type="button" onClick={() => dispatch(setModal("ConnectedWallet"))}>
          {shortedAddress(_get(walletSelector, "wallet[0].address", ""))}
        </Button>
      </div >
    </div >
  )
}

export default CustomKoinConnectButton;