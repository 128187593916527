import React from "react";
import { Provider as ReactProvider } from "react-redux";
import generateStore from "./redux/index";
import AppRouter from "./AppRouter";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "@fontsource/roboto/900.css";
const store = generateStore();

const App = () => (
  <ReactProvider store={store}>
    <AppRouter />
  </ReactProvider>
);

export default App;
