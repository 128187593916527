import React from "react";
import { useSelector } from "react-redux";
import Connect from "./Connect";
import ModalDisclaimer from "./ModalDisclaimer";
import ModalConnectedWallet from "./ConnectedWallet";

const Modals = () => {
  // selector
  const currentModal = useSelector((state) => state.modals.modal);

  if (currentModal == null) {
    document.body.style.overflow = "auto";
  }

  return (
    <>
      <Connect />
      <ModalConnectedWallet />
      <ModalDisclaimer />
    </>
  );
};

export default Modals;
