// MODALS
export const SET_MODAL = "SET_MODAL";
export const SET_MODAL_DATA = "SET_MODAL_DATA";

//SETTINGS
export const SET_NETWORK = "SET_NETWORK";
export const SET_DARKMODE = "SET_DARKMODE";
export const SET_DISCLAIMER = "SET_DISCLAIMER";

// WALLET
export const SET_WALLET = "SET_WALLET";
export const SET_PROVIDER = "SET_PROVIDER";
export const SET_SIGNER = "SET_SIGNER";
export const SET_CONNECTED = "SET_CONNECTED";