import { combineReducers } from "redux";
// reducers
import modals from "./modals";
import wallet from "./wallet";
import settings from "./settings";

export default combineReducers({
  modals: modals,
  wallet: wallet,
  settings: settings
});
