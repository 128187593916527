const APP_CHAIN = process.env.REACT_APP_CHAIN;

let config = {
  ["MAINNET"]: {
    explorer: {
      name: "Koinosblocks",
      url: "https://koinosblocks.com/block/"
    },
    chain: {
      name: "Mainnet",
      id: "EiBZK_GGVP0H_fXVAM3j6EAuz3-B-l3ejxRSewi7qIBfSA==",
    },
    contracts: {
      sale: {
        address: "1MiHTZpvGTUq87KVLN2V7PbdFomfpYLWSA",
        launched: true,
      },
    },
    tokens: {
      koin: {
        name: "Koin",
        symbol: "koin",
        description: "Native token of Koinos.",
        decimals: "8",
        logoURI: "https://raw.githubusercontent.com/koinos/koinos-branding/master/assets/icons/koinos-icon-dark.png",
        address: "15DJN4a8SgrbGhhGksSBASiSYjGnMU8dGL",
        token_website: "https://koinos.io",
        coingecko_id: "",
        coinmarketcap_id: "",
      },
      sale: {
        name: "Chainge USDT",
        symbol: "USDT",
        description: "Wrapped Tether USD via chainge finance.",
        decimals: "8",
        logoURI: "https://raw.githubusercontent.com/koindx/token-list/main/src/images/mainnet/14MjxccMUZrtBPXnNkuAC5MLtPev2Zsk3N.png",
        address: "14MjxccMUZrtBPXnNkuAC5MLtPev2Zsk3N",
        allowance: true,
        token_website: "",
        coingecko_id: "",
        coinmarketcap_id: ""
      }
    },
  },
  ["HARBINGER"]: {
    explorer: {
      name: "Koinosblocks",
      url: "https://harbinger.koinosblocks.com/block/"
    },
    chain: {
      name: "Harbinger",
      id: "EiAAKqFi-puoXnuJTdn7qBGGJa8yd-dcS2P0ciODe4wupQ==",
    },
    contracts: {
      sale: {
        address: "1MiHTZpvGTUq87KVLN2V7PbdFomfpYLWSA",
        launched: false,
      },
    },
    tokens: {
      koin: {
        name: "tKoin",
        symbol: "TKOIN",
        description: "Native token of Koinos.",
        decimals: "8",
        logoURI: "https://raw.githubusercontent.com/koinos/koinos-branding/master/assets/icons/koinos-icon-dark.png",
        address: "19JntSm8pSNETT9aHTwAUHC5RMoaSmgZPJ",
        token_website: "https://koinos.io",
        coingecko_id: "",
        coinmarketcap_id: "",
      }
    },
  },
};

export const CONFIG_BASE = config[APP_CHAIN];
