import { Box, Container, CssBaseline, IconButton, ThemeProvider, useMediaQuery, useTheme } from "@mui/material";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { SnackbarProvider } from "notistack";
import React from "react";
import { Outlet } from "react-router-dom";
// icons
import CloseIcon from "@mui/icons-material/Close";
import LogoWhite from "../../assets/images/logo_white.svg";

// components
import Footer from "../Footer";
import Modals from "../Modals";

// themes
import themeOptions from "../../theme/main";

const Layout = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const design = responsiveFontSizes(createTheme(themeOptions));

  const notistackRef = React.createRef();
  const SnackbarActions = (key) => (
    <IconButton component="span" onClick={() => notistackRef.current.closeSnackbar(key)}>
      <CloseIcon sx={{ color: "text.main" }} />
    </IconButton>
  );

  return (
    <ThemeProvider theme={createTheme(design)}>
      <CssBaseline />
      <SnackbarProvider
        ref={notistackRef}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        autoHideDuration={2000}
        action={(key) => SnackbarActions(key)}
      >
        <Box sx={{ backgroundColor: theme.palette.background.main }}>
          <Box sx={{ width: "100%" }} marginTop={"4em"} paddingY={"3em"} display={"flex"} justifyContent={"center"}>
            <img src={LogoWhite} alt="KoinDX logo" style={{ maxHeight: matches ? "28px" : "48px", float: "left" }} />
          </Box>
          <Container
            sx={{ minWidth: "calc(100vw - 18px)", minHeight: `calc(100vh - ${matches ? "56px" : "64px"})` }}
          >

            <Outlet />
            <Modals />
          </Container>
          <Footer />
        </Box>
      </SnackbarProvider>
    </ThemeProvider>
  );
};

export default Layout;
